import React from "react"
import { Link, graphql } from "gatsby"

import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

// import FJudgeToolbar from "../../../src/components/toolbar.js"
import Layout from "../../components/layout"
// import Layout from "../components/layout"
import SEO from "../../components/seo"

import '../../css/global.css'
import '../../css/scoreflex.css'
// import '../../../src/css/scores2d.css'

const classmap = { 
  '-99' : ' bg-gray-400 text-black ',
  '-98' : ' pause-cell ',
  '3' : ' bg-green-700 text-white ',
  '2' : ' bg-green-500 text-white ',
  '1' : ' bg-green-300 text-black ',
  '0' : ' bg-yellow-300 text-black ',
  '-1' : ' bg-red-200 text-black ',
  '-2' : ' bg-red-400 text-white ',
  '-3' : ' bg-red-600 text-white ',
}

/* alternate to 'splice' that works in chaining (https://stackoverflow.com/a/15621345/1766526) */ 
// Array.prototype.insert = function(index) {
//   this.splice.apply(this, [index, 0].concat(
//       Array.prototype.slice.call(arguments, 1)));
//   return this;
// }

/* alternate to 'splice' that doesn't mutate and works in chaining (https://stackoverflow.com/a/38181008/1766526) */ 
// const insert = (arr, index, ...newItems) => [
//   // part of the array before the specified index
//   ...arr.slice(0, index),
//   // inserted items
//   ...newItems,
//   // part of the array after the specified index
//   ...arr.slice(index)
// ]

const scoreclass = ((s) => {
  let scorevalue = (s === -99) ? s : (s > 3) ? 3 : (s < -3) ? -3 : s
  return classmap[scorevalue.toString()]
})

class ScoresPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeCol: 9
    }
  }

  // componentDidMount() {
  //   console.log(`did-mount - ${this.state.activeCol}`)
  // }

  // componentDidUpdate() {
  //   console.log(`did-update - ${this.state.activeCol}`)
  // }

  // threeD = (key) => {
  //   const c = this.state.activeCol
  //   const x = (key === c || key === c-1 || key === c+1) ? `threed-front` : 
  //     (key === c-2 || key === c-2) ? `threed-left` : 
  //     (key === c+3 || key === c+3) ? `threed-right` : `threed-back`;
  //   return ''
  // }


  render() {
    const { data } = this.props
    // console.log(data)
    const sheets = data.allGSheetsSheet.edges
    const sites = sheets[0].node.childrenGSheetsSite
    const header_array = sheets[0].node.arraydata.headers.slice(sheets[0].node.arraydata.headers.indexOf('D1'), 100)
    const pause = header_array.indexOf('W1')
    const draft_array = header_array.slice(0, pause)
    const weeks_array = header_array.slice(pause, 100) 
    // console.log(draft_array)
    // console.log(weeks_array)
    // console.log(header_array)
    // console.log(sites)
    const yaml = data.allYaml.edges

    const getSlug = function(week_number) {
      // console.log(yaml[0])
      const y = yaml.filter(y => y.node.meta.week === week_number)
      // console.log(y)
      return (y.length > 0) ? [ y[0].node.fields.slug, y[0].node.meta.tooltip ] : [null, null]
    }

    const drafts = sites.map((s) => {
      return s.arraydata.scorearray.slice(0, draft_array.length)
    })
    const weeks = sites.map((s) => {
      return s.arraydata.scorearray.slice(draft_array.length, 100)
    })

    // console.log(drafts)
    // find columns where all values are blank, for collapsing on mobile
    const dead_drafts = draft_array.reduce((acc, curr, idx) => {
      const vals = drafts.map((d) => d[idx])
      if (vals.filter(v => v > -90).length === 0) acc.push(idx)
      return acc
    }, [])
    // console.log(dead_drafts)

    const dead_weeks = weeks_array.reduce((acc, curr, idx) => {
      const vals = weeks.map((w) => w[idx])
      if (vals.filter(v => v > -90).length === 0) acc.push(idx)
      return acc
    }, [])
    // console.log(dead_weeks)

    const displaydata = (d) => { return (d < -90) ? `&nbsp;` : d.toString() }

    return(
      <Layout fullwidth="true">
        <SEO
          title="The Fantasy Judge | Scores"
          description="" 
        />
        <div className="font-monda mt-6 mr-4 scoreboard flex flex-row flex-no-wrap lg:justify-center">

          <div className="flex flex-row flex-no-wrap flex-none">
            <div className="flex flex-col mr-2">
              <div className="blank">&nbsp;</div>
              <div className="blank">&nbsp;</div>
              {
                sites.map((s, idx) => <div className="div-title px-1" key={idx}>
                  <span className="md:hidden">{s.nickname}</span>
                  <span className="hidden md:block">{s.fullname}</span>
                </div>)
              }
            </div>
            <div className="flex flex-col ml-px mr-2">
              <div className="blank">&nbsp;</div>
              <div className="blank">&nbsp;</div>
              {
                sites.map((s, idx) => <div className="div-score bg-orange-300 text-center font-bold my-px py-px" key={idx}>
                  <span className="px-1">{s.rawscore}</span>
                </div>)
              }
            </div>
          </div>

          { (dead_drafts.length === draft_array.length) ? null : (
            <div className="flex flex-col flex-none mr-2">
              <div className="section-title bg-indigo-700 text-white mx-px">
                <span className={(dead_drafts.length > 2) ? 'md:hidden' : 'hidden'}>draft</span>
                <span className={(dead_drafts.length > 2) ? 'hidden md:block' : ''}>draft verdicts</span>
              </div>
              <div className={`flex flex-row`}>
                {
                  draft_array.map((e, cidx) => 
                  <div className={`flex flex-col mx-px ${(dead_drafts.includes(cidx) ? 'dead-col' : 'live-col')}`} key={cidx}>
                    <div className="column-title bg-indigo-700 text-white">{e.substr(1,5)}</div>
                    {
                      drafts.map((e, ridx) => <div className={`div-data ${scoreclass(e[cidx])} my-px py-px`} key={ridx}
                        dangerouslySetInnerHTML={{__html: displaydata(e[cidx])}}
                      ></div>)
                    }
                  </div>
                  )
                }
              </div>
            </div>)
          }

          <div className="flex flex-col flex-none mr-2 sm:mr-0">
            <div className="section-title bg-blue-500 text-white mx-px">weekly verdicts</div>
            <div className="flex flex-row">
              {
                weeks_array.map((e, cidx) => {
                const week = e.substr(1,5)
                let [slug, tooltip] = getSlug(parseInt(week))
                return (
                <div className={`flex flex-col mx-px ${(dead_weeks.includes(cidx) ? 'dead-col' : 'live-col')}`} key={cidx}>
                  
                  {(slug) ? 
                  <Tippy content={tooltip}>
                    <div className="column-title bg-blue-500 text-white">
                      <Link to={slug} style={{boxShadow: `none`, textDecoration: `none`, color: `inherit` }}>
                        {week}
                      </Link>
                    </div>
                  </Tippy>
                  : <div className="column-title bg-blue-500 text-white">{week}</div>}
                  
                  {
                    weeks.map((e, ridx) => <div className={`div-data ${scoreclass(e[cidx])} text-sm my-px py-px`} key={ridx}
                      dangerouslySetInnerHTML={{__html: `<span>${displaydata(e[cidx])}</span>`}}
                    ></div>)
                  }
                </div>
                )})
              }
            </div>
          </div>

          <div className="placeholder lg:hidden">&nbsp;</div>

        </div>

        <div className="text-center mt-6">read more about the fantasy football experts <Link to={`/help/experts`}>here</Link></div>

        { (dead_drafts.length !== draft_array.length) ? null : (
          <div className="w-full text-center mt-8">Draft Verdicts will start to be posted around the mid-point of the season</div>
        ) }
      </Layout>
    )
  
  }

}

export default ScoresPage

export const pageQuery = graphql`
  query {
    allGSheetsSheet(filter: {year: {eq: 2020}})  {
      edges {
        node {
          childrenGSheetsSite {
            site
            fullname
            nickname
            score
            rawscore
            arraydata {
              scorearray
            }
          }
          id
          sport
          title
          year
          arraydata {
            headers
          }
        }
      }
    }
    allYaml(filter: {meta: {season: {eq: 2020}}}) {
      edges {
        node {
          content {
            argument
          }
          meta {
            week
            sport
            season
            date(formatString: "MMMM DD, YYYY")
            tooltip
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
